import React from 'react';
import logo from '../../assets/tblogo1.png';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const path = window.location.pathname;
  const openSideNav = () => {
    var sideNav: any = document.getElementById('sideNav');
    sideNav.classList.toggle('active');
  };
  return (
    <div
      className="md:side-nav flex flex-col w-[226px] bg-[#F7F8FA] z-10 fixed  top-16 left-0 min-h-screen h-full"
      id="sideNav"
    >
      <div className="mt-[34px] pl-[31px] pr-[25px]">
        <div>
          <ul>
            {/* <li
              className={`sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav ${path === '/dashboard' ? 'active' : ''}`}
            >
              <Link
                to="/dashboard"
                className="flex gap-4 sm:text-base text-sm items-center w-full    font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'> Dashboard</span>
              </Link>
            </li> */}
            <li
              className={`sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav ${path === '/assessments' ? 'active ' : ''
                }`}
            >
              <Link
                to="/assessments"
                className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'>Assessments</span>
              </Link>
            </li>
            {/* <li
              className={`sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav ${path === '/courses' ? 'active ' : ''}`}
            >
              <Link
                to="/courses"
                className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'>Courses</span>
              </Link>
            </li> */}
            <li className={`sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav ${path === '/jobs' ? 'active ' : ''}`}>
              <Link
                to="/jobs"
                className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'>Jobs</span>
              </Link>
            </li>
            {/* <li className="sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav">
              <Link
                to="/dashboard"
                className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'>Settings</span>
              </Link>
            </li> */}
            {/* <li className="sm:mb-3 mb-2 rounded-lg sm:py-3 sm:px-4 py-3 px-4 sideBarNav">
              <Link
                to="/dashboard"
                className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
              >

                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7498 3.94551C12.7498 4.80088 12.7498 5.65624 12.7498 6.51161C12.7498 7.04962 12.379 7.43035 11.8415 7.43176C10.6149 7.43552 9.38883 7.43505 8.16226 7.43176C7.62479 7.43035 7.24832 7.0529 7.24785 6.51536C7.24551 4.7929 7.24551 3.07043 7.24785 1.34796C7.24832 0.802443 7.62667 0.429688 8.17071 0.429688C9.39306 0.429688 10.6159 0.429688 11.8382 0.429688C12.3752 0.429688 12.7498 0.80526 12.7503 1.34374C12.7503 2.21084 12.7503 3.07794 12.7503 3.94504L12.7498 3.94551Z" fill={'#BEC3D7'} />
                  <path d="M0.75 8.92551C0.75 8.06217 0.75 7.19929 0.75 6.33594C0.75 5.81859 1.11661 5.43175 1.63484 5.42894C2.8769 5.42189 4.11896 5.42236 5.36102 5.42894C5.87408 5.43128 6.24914 5.80685 6.25102 6.32092C6.25618 8.05888 6.25618 9.79684 6.25102 11.5353C6.24961 12.0606 5.86422 12.4301 5.3413 12.4301C4.11473 12.4301 2.88863 12.4301 1.66206 12.4301C1.12553 12.4301 0.750469 12.0536 0.75 11.516C0.75 10.6527 0.75 9.7898 0.75 8.92645L0.75 8.92551Z" fill={'#BEC3D7'} />
                  <path d="M10.0025 12.4287C9.38947 12.4287 8.77642 12.4287 8.1629 12.4287C7.63294 12.4287 7.25131 12.0574 7.24802 11.5264C7.24333 10.7922 7.24239 10.0579 7.24802 9.3237C7.25225 8.8101 7.62214 8.43078 8.13427 8.42796C9.37633 8.42092 10.6184 8.42139 11.86 8.42796C12.3838 8.43031 12.749 8.81621 12.7495 9.34248C12.7495 10.0687 12.75 10.7955 12.7495 11.5217C12.7495 12.0504 12.3707 12.4278 11.8417 12.4283C11.2286 12.4287 10.6156 12.4283 10.0021 12.4283L10.0025 12.4287Z" fill={'#BEC3D7'} />
                  <path d="M3.50236 0.42892C4.11541 0.42892 4.72846 0.42892 5.34198 0.42892C5.86115 0.42892 6.24465 0.791816 6.25123 1.31151C6.26014 2.05749 6.26108 2.80347 6.25123 3.54945C6.24465 4.05789 5.87194 4.42688 5.36216 4.4297C4.1201 4.43627 2.87804 4.43674 1.63645 4.4297C1.11682 4.42642 0.751147 4.03958 0.750209 3.52223C0.750209 2.79174 0.749739 2.06172 0.750209 1.33123C0.751147 0.809186 1.12996 0.429859 1.65101 0.42892C2.26828 0.42845 2.88509 0.42892 3.50236 0.42892Z" fill={'#BEC3D7'} />
                </svg>

                <span className='text-[#6B7A99]'>Help</span>
              </Link>
            </li> */}
            <li className="sm:mb-3 mb-2">
              <div
                onClick={() => {
                  localStorage.clear();
                  (window as Window).location = '/login';
                }}
                className="inline-flex cursor-pointer sm:text-base text-sm items-center w-full sm:py-3 sm:px-4 py-2 px-3 rounded-lg font-medium"
              >
                <svg
                  className="mr-2"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.56109 8.6286C6.75109 8.6286 6.94109 8.6986 7.09109 8.8486C7.38109 9.1386 7.38109 9.6186 7.09109 9.9086L5.06109 11.9386L7.09109 13.9686C7.38109 14.2586 7.38109 14.7386 7.09109 15.0286C6.80109 15.3186 6.32109 15.3186 6.03109 15.0286L3.47109 12.4686C3.18109 12.1786 3.18109 11.6986 3.47109 11.4086L6.03109 8.8486C6.18109 8.6986 6.37109 8.6286 6.56109 8.6286Z"
                    fill="#BEC3D7"
                  />
                  <path
                    d="M4.0683 11.1914L14.2383 11.1914C14.6483 11.1914 14.9883 11.5314 14.9883 11.9414C14.9883 12.3514 14.6483 12.6914 14.2383 12.6914L4.0683 12.6914C3.6583 12.6914 3.3183 12.3514 3.3183 11.9414C3.3183 11.5314 3.6583 11.1914 4.0683 11.1914Z"
                    fill="#BEC3D7"
                  />
                  <path
                    d="M12.2383 3.25C17.3883 3.25 20.9883 6.85 20.9883 12C20.9883 17.15 17.3883 20.75 12.2383 20.75C11.8283 20.75 11.4883 20.41 11.4883 20C11.4883 19.59 11.8283 19.25 12.2383 19.25C16.5083 19.25 19.4883 16.27 19.4883 12C19.4883 7.73 16.5083 4.75 12.2383 4.75C11.8283 4.75 11.4883 4.41 11.4883 4C11.4883 3.59 11.8283 3.25 12.2383 3.25Z"
                    fill="#BEC3D7"
                  />
                </svg>
                <span className='text-[#6B7A99]'>Log out</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      {/* <div className="absolute top-2 right-2 lg:hidden">
        <button type="button" onClick={openSideNav}>
          <svg
            className="md:w-6 md:h-6 w-5 h-5 icon-cross"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1718 6.42178C16.6124 5.98115 16.6124 5.26865 16.1718 4.83271C15.7312 4.39678 15.0187 4.39209 14.5827 4.83271L9.00459 10.4108L3.42178 4.82803C2.98115 4.3874 2.26865 4.3874 1.83271 4.82803C1.39678 5.26865 1.39209 5.98115 1.83271 6.41709L7.41084 11.9952L1.82803 17.578C1.3874 18.0187 1.3874 18.7312 1.82803 19.1671C2.26865 19.603 2.98115 19.6077 3.41709 19.1671L8.99521 13.589L14.578 19.1718C15.0187 19.6124 15.7312 19.6124 16.1671 19.1718C16.603 18.7312 16.6077 18.0187 16.1671 17.5827L10.589 12.0046L16.1718 6.42178Z"
              fill="#BEC3D7"
            />
          </svg>
        </button>
      </div>
      <div>
        <ul>
          <li
            className={`sm:mb-3 mb-2 ${path === '/dashboard' ? 'active ' : ''}`}
          >
            <Link
              to="/dashboard"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3"
              >
                <path d="M10.5 19.9V4.1C10.5 2.6 9.86 2 8.27 2H4.23C2.64 2 2 2.6 2 4.1V19.9C2 21.4 2.64 22 4.23 22H8.27C9.86 22 10.5 21.4 10.5 19.9Z" />
                <path d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9Z" />
                <path d="M22 19.9V18.1C22 16.6 21.36 16 19.77 16H15.73C14.14 16 13.5 16.6 13.5 18.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9Z" />
              </svg>
              Dashboard
            </Link>
          </li>
          <li
            className={`sm:mb-3 mb-2 ${
              path === '/assessments' ? 'active ' : ''
            }`}
          >
            <Link
              to="/assessments"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3 assessment"
              >
                <path
                  d="M11 19.5H21"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 12.5H21"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 5.5H21"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 5.5L4 6.5L7 3.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 12.5L4 13.5L7 10.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 19.5L4 20.5L7 17.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Assessments
            </Link>
          </li>
          <li
            className={`sm:mb-3 mb-2 ${path === '/courses' ? 'active ' : ''}`}
          >
            <Link
              to="/courses"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3"
              >
                <path
                  d="M3.5 18V7C3.5 3 4.5 2 8.5 2H15.5C19.5 2 20.5 3 20.5 7V17C20.5 17.14 20.5 17.28 20.49 17.42"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.35 15H20.5V18.5C20.5 20.43 18.93 22 17 22H7C5.07 22 3.5 20.43 3.5 18.5V17.85C3.5 16.28 4.78 15 6.35 15Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 7H16"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10.5H13"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Courses
            </Link>
          </li>
          <li className={`sm:mb-3 mb-2 ${path === '/jobs' ? 'active ' : ''}`}>
            <Link
              to="/jobs"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3"
              >
                <path
                  d="M7.99983 22H15.9998C20.0198 22 20.7398 20.39 20.9498 18.43L21.6998 10.43C21.9698 7.99 21.2698 6 16.9998 6H6.99983C2.72983 6 2.02983 7.99 2.29983 10.43L3.04983 18.43C3.25983 20.39 3.97983 22 7.99983 22Z"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.62012 11.27C4.87012 12.81 7.41012 13.74 10.0001 14.03"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Jobs
            </Link>
          </li>
          <li className="sm:mb-3 mb-2">
            <Link
              to="/dashboard"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3"
              >
                <path
                  d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Settings
            </Link>
          </li>
          <li className="sm:mb-3 mb-2">
            <Link
              to="/dashboard"
              className="flex gap-4 sm:text-base text-sm items-center w-full  text-[#6B7A99]  font-medium"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-3"
              >
                <path
                  d="M17 18.43H13L8.54999 21.3899C7.88999 21.8299 7 21.36 7 20.56V18.43C4 18.43 2 16.43 2 13.43V7.42993C2 4.42993 4 2.42993 7 2.42993H17C20 2.42993 22 4.42993 22 7.42993V13.43C22 16.43 20 18.43 17 18.43Z"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9998 11.3601V11.1501C11.9998 10.4701 12.4198 10.1101 12.8398 9.82013C13.2498 9.54013 13.6597 9.18014 13.6597 8.52014C13.6597 7.60014 12.9198 6.86011 11.9998 6.86011C11.0798 6.86011 10.3398 7.60014 10.3398 8.52014"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9951 13.75H12.0041"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Help
            </Link>
          </li>
        </ul>
      </div>
      */}
    </div>
  );
};

export default Sidebar;