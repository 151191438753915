import React, { useState } from 'react'
import plus from '../../assets/new-home/plus.svg';
function FAQ({ quesiton, ans }) {

    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={'accordian cursor-pointer'} onClick={() => { setIsOpen(prev => !prev) }}>
            <div className='flex flex-row items-center justify-between py-4 border-b border-b-[#DFDFDF]'>
                <h3 className='text-[#191919] font-semibold lg:text-[26px] text-base ff-pop leading-tight flex-1 lg:max-w-full  max-w-[80%]'>{quesiton}</h3>
                <span className={`${isOpen ? 'rotate-45 transition-all' : 'transition-all'} w-5 h-5 lg:w-10 lg:h-10` }>
                    <img src={plus} alt="" />
                </span>
            </div>
            <div className={isOpen ? 'block' : 'hidden'}>
                <p className='ff-mulish font-medium my-2 lf:text-lg text-sm leading-tight text-[#061927]'>{ans}</p>
            </div>
        </div >
    )
}

export default FAQ