
import person from '../../assets/new-home/Ellipse 1967.png';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Pagination } from 'swiper/modules';
const CustomerSlider = () => {

    return (
        <section className="lg:pb-[110px] pb-10 px-4 lg:px-0" >
            <div className=' mys-wipe mx-auto'>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3.8}
                    breakpoints={
                        {
                            // when window width is >= 320px
                            10: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                           
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                            },
                            1280: {
                                slidesPerView: 3.3,
                                spaceBetween: 5,
                            },
                            1350: {
                                slidesPerView: 3.8,
                                spaceBetween: 5,
                            },
                        }
                    }

                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                >
                   
                    <SwiperSlide>
                        <div className='-ml-4 flex flex-row justify-center -mb-12'>
                            <img src={person} width={114} height={114} className='aspect-square' alt="" />
                        </div>
                        <div className="bg-cutomer-slider-2  px-4 md:px-[55px] pb-4 lg:pb-[50px] pt-20">
                            <p className='ff-mulish font-normal text-base lg:text-lg leading-tight text-center'>Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='-ml-4 flex flex-row justify-center -mb-12'>
                            <img src={person} width={114} height={114} className='aspect-square' alt="" />
                        </div>
                        <div className="bg-cutomer-slider-1 px-4 md:px-[55px] pb-4 lg:pb-[50px] pt-20">
                            <p className='ff-mulish font-normal text-base lg:text-lg leading-tight text-center'>Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='-ml-4 flex flex-row justify-center -mb-12'>
                            <img src={person} width={114} height={114} className='aspect-square' alt="" />
                        </div>
                        <div className="bg-cutomer-slider-2  px-4 md:px-[55px] pb-4 lg:pb-[50px] pt-20">
                            <p className='ff-mulish font-normal text-base lg:text-lg leading-tight text-center'>Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='-ml-4 flex flex-row justify-center -mb-12'>
                            <img src={person} width={114} height={114} className='aspect-square' alt="" />
                        </div>
                        <div className="bg-cutomer-slider-1 px-4 md:px-[55px] pb-4 lg:pb-[50px] pt-20">
                            <p className='ff-mulish font-normal text-base lg:text-lg leading-tight text-center'>Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='-ml-4 flex flex-row justify-center -mb-12'>
                            <img src={person} width={114} height={114} className='aspect-square' alt="" />
                        </div>
                        <div className="bg-cutomer-slider-2  px-4 md:px-[55px] pb-4 lg:pb-[50px] pt-20">
                            <p className='ff-mulish font-normal text-base lg:text-lg leading-tight text-center'>Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
        </section>
    )
}

export default CustomerSlider;