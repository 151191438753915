import React, { useState } from "react";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler } from "chart.js";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import { axiosPrivate, axiosPublic } from '../../api/axios';



import "react-circular-progressbar/dist/styles.css";
import "./pdf.css";
import heroBanner1 from "../../assets/pdf/hero-banner-1.webp";
import heroBanner2 from "../../assets/pdf/hero-banner-2.webp";
import tbLogo from "../../assets/pdf/tblogo1.webp";
import skillProfiling1 from "../../assets/pdf/skill-profiling-1.webp";
import skillProfiling2 from "../../assets/pdf/skill-profiling-2.webp";
import skillProfiling3 from "../../assets/pdf/skill-profiling-3.webp";
import infoImg from "../../assets/pdf/info-img.webp";
import assessmentImg from "../../assets/pdf/Assessment-img.webp";
import assessment2 from "../../assets/pdf/Assessment-2.webp";
import score1 from "../../assets/pdf/score-1.webp";
import score2 from "../../assets/pdf/score-2.webp";
import score3 from "../../assets/pdf/score-3.webp";
import score4 from "../../assets/pdf/score-4.webp";
import score5 from "../../assets/pdf/score-5.webp";
import tableImg from "../../assets/pdf/table-img.webp";
import footerBgx from "../../assets/pdf/footer-bg.webp";
import RadialSeparators from "../../assets/pdf/RadialSeprator";
ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);


function ReportPdf({ id }) {
  const [results, setResults] = useState({});
  const [userName, setuserName] = useState('');
  const [scors, setScors] = useState({});
  const [assessment, setAssessment] = useState({});
  React.useEffect(() => {
    (async () => {
      try {
        const res = await axiosPublic.get(`/details-report/${id}`);
        const resultData = res.data;
        setResults(resultData);
        setScors(resultData.scors);
        setAssessment(resultData.assessment);
        setuserName(resultData.name);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    })();
  }, [id]);

  let labels = [];
  let dataSetStudentScor = [];
  let dataSetIndustryScor = [];
  let dataSetBellowScor = [];

  Object.entries(scors).forEach(([competencyKey, competencyValue]) => {
    const value = competencyValue;
    labels.push(competencyKey);

    // Ensure numeric values for the dataset
    const studentScore = parseFloat(value.total_percentage.toFixed(2));
    dataSetStudentScor.push(studentScore);

    // Calculate industry score with conditions
    let industryScore = studentScore + 10;
    if (industryScore > 81) {
      industryScore = studentScore;
    }
    if (industryScore < 70) {
      industryScore = 75;
    }
    dataSetIndustryScor.push(industryScore);

    // Baseline score
    dataSetBellowScor.push(20);
  });

  // Radar chart configuration
  const radarData = {
    labels: labels,
    
    datasets: [
      {
        label: "Top benchmark",
        data: dataSetIndustryScor,
        borderColor: "gray",
        backgroundColor: "rgba(128, 128, 128, 0.2)",
      },
      {
        label: userName,
        data: dataSetStudentScor,
        borderColor: "green",
        backgroundColor: "rgba(0, 128, 0, 0.2)",
      },
      {
        label: "Baseline average",
        data: dataSetBellowScor,
        borderColor: "lightblue",
        backgroundColor: "rgba(173, 216, 230, 0.2)",
      },
    ],
  };

  const radarOptions = {
    scales: {
      r: {
        beginAtZero: true,
        
        max: 100,
        ticks: {
          callback: function (value) {
            return `${value}%`; // Add percentage to ticks
          },
        },
       
      },
     
    },
  };

  return (
    <div style={{ pageBreakInside: 'avoid', margin: 0, padding: 0 }}>
      <div className="hero-section pr" style={{ pageBreakAfter: 'avoid' }}>
        <picture>
          <source media="(min-width:650px)" srcSet={heroBanner1} />
          <source media="(min-width:465px)" srcSet={heroBanner2} />
          <img className="w-100 h-screen object-cover" src={heroBanner2} alt="Hero-banner" />
        </picture>
        <div className="hero-content-wrapper container">
          <div className="hero-logo-wrapper text-right">
            <img className="hero-logo" src={tbLogo} alt="" />
          </div>
          <h1 className="hero-heading section-heading text-white">
            Skill Profiling <br />
            Assessment
          </h1>
        </div>
      </div>
      <div className="content-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <h1 className="section-heading text-black">Contents</h1>
        </div>
        <div className="content-item-wrapper pr">
          <div className="content-item container">
            <span>1.</span>
            <span>Skill Profiling Assessment</span>
          </div>
        </div>
        <div className="content-item-wrapper pr">
          <div className="content-item container">
            <span>2.</span>
            <span>Assessment Methodology Overview</span>
          </div>
        </div>
        <div className="content-item-wrapper pr">
          <div className="content-item container">
            <span>3.</span>
            <span>Your Competency Score</span>
          </div>
        </div>
        <div className="content-item-wrapper pr">
          <div className="content-item container">
            <span>4.</span>
            <span>Competency In a nutshell</span>
          </div>
        </div>
        <div className="content-item-wrapper pr">
          <div className="content-item container">
            <span>5.</span>
            <span>Contact</span>
          </div>
        </div>
      </div>
      <div className="skill-profiling-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <h1 className="section-heading text-center">
            <span>Skill Profiling</span>{' '}
            {/* <br /> */}
            Assessment
          </h1>
          <p className="section-desc">
            <p className="w-full text-center !text-3xl !font-bold">Welcome to Your Role-Based Assessment Report</p>
            
            Congratulations on completing this role-specific assessment! We are
            excited to share this detailed report with you, which highlights
            your performance across critical skills and competencies essential
            for your role. This report aims to give you a clear view of your
            strengths, areas where you're excelling, as well as specific
            opportunities for further growth and skill development.
          </p>
          <p className="fw-700 section-desc">
            In the following pages, you'll find:
          </p>
          <div className="skill-profiling-item">
            <div className="skill-profiling-item-img">
              <img className="w-100" src={skillProfiling1} alt="" />
            </div>
            <div className="skill-profiling-item-info">
              <h3 className="skill-profiling-item-title">Key Strengths</h3>
              <p className="skill-profiling-item-desc">
                Areas where you're demonstrating proficiency and making an
                impact in your role.
              </p>
            </div>
          </div>
          <div className="skill-profiling-item">
            <div className="skill-profiling-item-img">
              <img className="w-100 object-contain" src={skillProfiling2} alt=""  />
            </div>
            <div className="skill-profiling-item-info">
              <h3 className="skill-profiling-item-title">
                Development Opportunities
              </h3>
              <p className="skill-profiling-item-desc">
                Insights into capabilities where focused improvement can enhance
                your performance and effectiveness
              </p>
            </div>
          </div>
          <div className="skill-profiling-item">
            <div className="skill-profiling-item-img">
              <img className="w-100" src={skillProfiling3} alt="" />
            </div>
            <div className="skill-profiling-item-info">
              <h3 className="skill-profiling-item-title">
                Learning Recommendation
              </h3>
              <p className="skill-profiling-item-desc">
                Tailored guidance and actionable steps to help you build on your
                existing skills and make targeted improvements that align with
                the demands of your role
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="info-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <p className="section-desc">
            As you explore this report, consider how these insights align with
            your career aspirations. Take time to reflect on how you can apply
            the recommendations in your day-to-day responsibilities, leveraging
            your strengths while addressing any challenges. By engaging actively
            with this feedback, you're setting a strong foundation for growth,
            adaptability, and long-term success in your role.
          </p>
          <p className="section-desc">
            We are here to support you in reaching your full potential. Embrace
            this journey as an opportunity to deepen your expertise, unlock new
            skills, and achieve greater success. Happy learning and development!
          </p>
          <div className="info-section-img-zx">
            <img className="w-full h-full" src={infoImg} alt="" />
          </div>
        </div>
      </div>
      <div className="Assessment-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Assessment</span>{' '}
            {/* <br /> */}
            Methodology Overview
          </h1>
          {/* <br /> */}
          <p className="section-desc">
            This assessment is designed to evaluate learners across core
            competencies and specific capabilities required for their roles.
            <br/>
            Our approach combines -
          </p>
         
          <ul className="list-disc list-inside">
            <li>Scenario-based questions,</li>
            <li>Practical problem-solving tasks, and</li>
            <li>
              Objective performance metrics to measure real-world application of
              skills.
            </li>
          </ul>
          <p className="section-desc">
            By simulating role-specific challenges, the assessment captures each
            learner's ability to understand, analyze, and respond effectively to
            situations they may encounter in their job.
          </p>
          <div className="info-section-img-zxx">
            <img className="w-100" src={assessmentImg} alt="" />
          </div>
          <p className="section-desc">
            Each competency is further broken down into key capabilities,
            allowing for a precise analysis of strengths and improvement areas.
            The scoring framework is designed to reflect proficiency levels,
            with thresholds set to identify high performers, emerging talent,
            and areas where additional development may be beneficial.
          </p>
          <p className="section-desc">
            This structured and holistic approach ensures a balanced evaluation,
            offering clear, actionable insights that guide personal development
            and foster role-specific growth.
          </p>
        </div>
      </div>
      <div className="Assessment-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Assessment</span>{' '}
            {/* <br /> */}
            Scoring Methodology
          </h1>
          {/* <br /> */}
          <p className="section-desc !font-bold !text-3xl">Scores are segmented by thresholds</p>
          <ul className="list-disc list-inside">
            <li>
              Below 50% indicates foundational areas that may need targeted
              support.
            </li>
            <li>
              51%-75% suggests moderate proficiency where improvement efforts
              can be focused.
            </li>
            <li>
              Above 75% highlights strong capability and potential for advanced
              application.
            </li>
          </ul>
          <p className="section-desc">
            This detailed breakdown not only supports the learner's
            selfawareness but also aligns development initiatives with specific
            competencies, enhancing targeted learning strategies for continued
            growth.
          </p>
          <div className="!mt-10">
            <img className="w-100" src={assessment2} alt="" />
          </div>
        </div>
      </div>
      <div className="score-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Your Competency</span>{' '}
            {/* <br /> */}
            Score
          </h1>
          {/* <br /> */}
          <div className="score-section-wrapper">
            <div className="block-title-wrapper d-flex align-items-center">
              <h4 className="block-title Competency w-60">Competency</h4>
              <h4 className="block-title Score w-40 text-center">Score</h4>
            </div>
            {Object.entries(scors)
              .sort(
                ([, a], [, b]) =>
                  b.total_percentage - a.total_percentage
              )
              .map(([competencyKey, competencyValue], index) => (
                <div className="score-block-wrapper d-flex align-items-center">
                  <div className="competency-block-item d-flex align-items-center w-60">
                    <img src="https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/score-1.webp" alt={competencyKey} />
                    <h3 className="competency-block-item-title section-desc fw-700">
                      {competencyKey}
                    </h3>
                  </div>
                  <div className="score-progress-wrapper w-40 text-center">
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbarWithChildren
                        value={Math.round(competencyValue.total_percentage)}
                        text={`${Math.round(competencyValue.total_percentage)}%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                        })}
                      >
                        <RadialSeparators
                          count={12}
                          style={{
                            background: "#fff",
                            width: "2px",
                            height: `${10}%`,
                          }}
                        />
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="Assessment-section section-padding section section-bg" style={{  }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Competency</span>{' '}
            {/* <br /> */}
            In a nutshell
          </h1>
          {/* <br /> */}
          <div className="w-full max-w-[800px] mx-auto"><Radar data={radarData} options={radarOptions} /></div>
        </div>
      </div>
      <div className="Assessment-section section-padding section section-bg" style={{  }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Capability</span>{' '}
            {/* <br /> */}
            In a nutshell
          </h1>
          {/* <br /> */}
          <p className="section-desc !font-bold !text-3xl">Scores are segmented by thresholds</p>
          <ul className="list-disc list-inside">
            <li>
              <b>Below 50%</b> indicates foundational areas that may need
              targeted support.
            </li>
            <li>
              <b>51%-75%</b> suggests moderate proficiency where improvement
              efforts can be focused.
            </li>
            <li>
              <b>Above 75%</b> highlights strong capability and potential for
              advanced application.
            </li>
          </ul>
          <p className="section-desc">
            This detailed breakdown not only supports the learner's
            selfawareness but also aligns development initiatives with specific
            competencies, enhancing targeted learning strategies for continued
            growth.
          </p>
          <div className="max-w-[70%] mx-auto mt-4">
            {Object.entries(scors).map(([competencyKey, competencyValue]) =>
              competencyValue.capabilities.map((cap, index) => (
                <div className="sm:mb-6 mb-2 mt-4 ml-4 bg-gray-200  px-2 py-3 rounded-lg" key={`${competencyKey}-${index}`} style={{pageBreakInside: "avoid", }}>
                  <div className="flex items-center justify-between sm:mb-4 mb-2">
                    <div className="mb-1 text-base font-medium">
                      {cap.title}
                    </div>
                    <div className="mb-1 text-base font-medium">
                      {parseFloat(cap.percentage).toFixed(1)}% {/* Format to one decimal place */}
                    </div>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-2 mb-4 dark:bg-gray-700">
                    <div
                      className="bg-amber-300 h-2 rounded-full"
                      style={{ width: `${parseFloat(cap.percentage).toFixed(1)}%` }} // Format width value
                    ></div>
                  </div>
                </div>
                
              ))
            )}
          </div>
        </div>
      </div>

      <div className="score-section section-padding section section-bg" style={{ }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Your</span>{' '}
            {/* <br /> */}
            Strengths
          </h1>
          {/* <br /> */}
          <div className="score-section-wrapper">
            <div className="block-title-wrapper d-flex align-items-center">
              <h4 className="block-title Competency w-60">Competency</h4>
              <h4 className="block-title Score w-40 text-center">Score</h4>
            </div>
            {Object.entries(scors)
              .filter(([, competencyValue]) => competencyValue.total_percentage > 75) // Filter by total_percentage > 50
              .sort(
                ([, a], [, b]) =>
                  b.total_percentage - a.total_percentage
              )
              .map(([competencyKey, competencyValue], index) => (
                <div className="score-block-wrapper d-flex align-items-center" key={index}>
                  <div className="competency-block-item d-flex align-items-center w-60">
                    <img src="https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/score-1.webp" alt={competencyKey} />
                    <h3 className="competency-block-item-title section-desc fw-700">
                      {competencyKey}
                    </h3>
                  </div>
                  <div className="score-progress-wrapper w-40 text-center">
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbarWithChildren
                        value={Math.round(competencyValue.total_percentage)}
                        text={`${Math.round(competencyValue.total_percentage)}%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                        })}
                      >
                        <RadialSeparators
                          count={12}
                          style={{
                            background: "#fff",
                            width: "2px",
                            height: `${10}%`,
                          }}
                        />
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className="Assessment-section section-padding section section-bg"
        style={{  }}
      >
        <div className="container">
          <h1 className="section-heading">
            <span>Your</span>{' '}
            {/* <br /> */}
            Areas of Improvement/ Potential for Advance application
          </h1>
          {/* <br /> */}
          {Object.entries(scors).map(([competencyKey, competencyValue]) => (
            <div style={{ }} key={competencyKey}>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr style={{ border: "1px solid #ddd" }}>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20,
                        maxWidth:'50%',
                        width:500
                      }}
                      className="m-bold"
                    >
                      {competencyKey}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20
                      }}

                      className="m-bold"
                    >
                      Your Score
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20
                      }}
                      className="m-bold"
                    >
                      Areas of Improvement/ Potential for Advance application
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competencyValue.capabilities.map((cap, index) => {
                    let titleContent = "";
                    if (cap.percentage < 51) {
                      titleContent = "Score < 50%: Needs Improvement";
                    } else if (cap.percentage >= 51 && cap.percentage <= 75) {
                      titleContent = "Score 51% - 75%: Meets Basic Expectations";
                    } else {
                      titleContent = "Score > 75%: Exceeds Expectations";
                    }
                    return (
                      <tr key={index} style={{ border: "1px solid #ddd" }}>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize:22,
                            maxWidth:'50%',
                            width:500,
                            
                          }}
                          className="m-bold"
                        >
                          {cap.title} - ({titleContent})
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize:22,
                            
                          }}
                          className="m-bold"
                        >
                          {parseFloat(cap.percentage).toFixed(1)}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                          }}
                        >
                          {/* Render HTML safely */}
                          <div dangerouslySetInnerHTML={{ __html: cap.areasOfImprovement || "" }} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>

      <div className="Assessment-section section-padding section section-bg" style={{  }}>
        <div className="container">
          <h1 className="section-heading">
            <span>Our</span>{' '}
            {/* <br /> */}
            Learning Recommendation 
          </h1>
          {/* <br /> */}
          {Object.entries(scors).map(([competencyKey, competencyValue]) => (
            <div style={{  }} key={competencyKey}>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr style={{ border: "1px solid #ddd" }}>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20,
                        maxWidth:'50%',
                        width:500

                      }}
                      className="m-bold"
                    >
                      {competencyKey}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20
                      }}
                      className="m-bold"
                    >
                      Your Score
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize:20
                      }}
                      className="m-bold"
                    >
                      Learning Recommendation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competencyValue.capabilities.map((cap, index) => {
                    let titleContent = "";
                    if (cap.percentage < 51) {
                      titleContent = "Score < 50%: Needs Improvement";
                    } else if (cap.percentage >= 51 && cap.percentage <= 75) {
                      titleContent = "Score 51% - 75%: Meets Basic Expectations";
                    } else {
                      titleContent = "Score > 75%: Exceeds Expectations";
                    }
                    return (
                      <tr key={index} style={{ border: "1px solid #ddd" }}>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize:22,
                            maxWidth:'50%',
                            width:500
                            
                          }}
                          className="m-bold"
                        >
                          {cap.title} - ({titleContent})
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize:22,
                            
                          }}
                          className="m-bold"
                        >
                          {parseFloat(cap.percentage).toFixed(1)}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                          }}
                        >
                          {/* Render HTML safely */}
                          <div dangerouslySetInnerHTML={{ __html: cap.recommendations || "" }} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
      <footer className="pr" >
        <img className="w-100 object-cover h-screen" src={footerBgx} alt="" />
        <span className="footer-logo">
          <img className="w-100" src={tbLogo} alt="" />
        </span>
        <div className="footer-content-wrapper container">
          <h1 className="footer-title text-center m-0">
            Empower Your Workforce, Elevate Success
          </h1>
          <p className="section-desc text-center">
            Unleashing Potential Through Comprehensive Skills Profiling for
            Achieving Successful Employees.
          </p>
          <p className="contact-detail text-center">
            Chhandan Chakraborty | +91 9147064666
            <br />
            chandan@learningbooth.co
          </p>
        </div>
      </footer>
    </div>
  );
}

export default ReportPdf;