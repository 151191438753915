import React from 'react';
import MainLayout from '../../layout/MainLayout';
import StepSixHeader from '../../components/AssessmentsAndTests/StepSix/StepSixHeader';
import StepSixContent from '../../components/AssessmentsAndTests/StepSix/StepSixContent';
import PDF from '../../components/PDF/Report-Pdf';

const AssessmentResult = () => {
  const resultId = new URLSearchParams(window.location.search).get('resultId');
  const retakeUrl: any = new URLSearchParams(window.location.search).get(
    'retakeUrl'
  );
  const retake = () => {
    (window as Window).location = retakeUrl || '/';
  };
  return (
    <PDF id={resultId || ''} />
  );
};

export default AssessmentResult;
